html,
body,
#root {
  width: 100%;
  height: 100%;
  position: relative;
  //background: linear-gradient(0deg, #eef1f8, #eef1f8), #f6f9ff;
  background: transparent;
}

@font-face {
  font-family: Proxima;
  src: url('../fonts/Proxima Nova Regular.otf') format('opentype');
}

@font-face {
  font-family: Proxima;
  font-weight: bold;
  src: url('../fonts/Proxima Nova Semibold.otf') format('opentype');
}

@font-face {
  font-family: Proxima;
  font-weight: 100;
  src: url('../fonts/Proxima Nova Thin.otf') format('opentype');
}

* {
  font-family: 'Proxima', sans-serif !important;
}

a {
  text-decoration: none;
  color: #ef4d7e;
}
.loader {
  position: relative !important;
}

@page {
  size: 29.7cm 42cm;
  margin: 5mm 5mm 5mm 5mm;
}

@media print {
  .hidePrint {
    display: none;
  }
  .showPrint {
    display: block;
  }
}

@media not print {
  .showPrint {
    display: none;
  }
}


//Disable outer/inner spin button for number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
